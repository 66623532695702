<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1" v-if="navigete!=null && navigete!='' && navigete!='null'">
        การเงิน <v-icon>mdi-chevron-right</v-icon>
        สรุปยอดและตัดเงินร้านค้า
      </v-card-title>
    </v-card>

    <v-card class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon size="20" color="primary">fa-sliders-h</v-icon><span class="my-3 ml-3">ค้นหารายการ</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row class="mx-0 ">
          <v-col cols="12" class="hidden-sm-and-up py-3 pl-0 pb-0">
            <span><b> เลือกร้านค้า : </b></span>
          </v-col>
          <v-col cols="12" md="5" class="py-3 pl-0 pb-0" style="display: flex;">
            <span class="py-3 mr-2 hidden-xs-only"><b> เลือกร้านค้า : </b></span>
            <v-autocomplete
                v-model="sh_shop_select"
                :items="sh_shop_list"
                outlined
                item-text="name"
                item-value="id"
                dense
              ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mx-0 ">
          <v-col cols="12" class="hidden-sm-and-up py-3 pl-0 pb-0">
            <span><b> จัดเรียงโดย : </b></span>
          </v-col>
          <v-col cols="12" md="5" class="py-3 pl-0 pb-0" style="display: flex;">
            <span class="py-3 mr-2 hidden-xs-only"><b> จัดเรียงโดย : </b></span>
            <v-autocomplete
                v-model="shop_date_type_select"
                :items="shop_date_type_list"
                outlined
                item-text="name"
                item-value="id"
                dense
              ></v-autocomplete>
          </v-col>
          <v-col cols="3" md="3" class="py-4 pl-0 pb-0">
            <v-btn dark color="error" class="fn-14" @click="get_uninvoice_group(sh_shop_select), tab = 'tab-1'"><v-icon>mdi-magnify</v-icon>ค้นหา</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
        <v-tabs v-model="tab"  outlined  slider-color="primary">
          <v-tab  href="#tab-1"> ร้านค้าที่ยังไม่ได้สรุปยอดและตัดเงิน</v-tab>
          <v-tab  href="#tab-2" style=" pointer-events: none;"> รายละเอียดแยกตามวันที่ </v-tab>
        </v-tabs>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item  value="tab-1">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <!-- <v-col cols="12" align="right" class="pb-3">
                    <v-btn class="ma-1" color="primary" @click="goto_create_bill()">สร้างบิลใบแจ้งหนี้</v-btn>
                  </v-col> -->
                  <v-col cols="12">
                    <v-data-table
                      :headers="headers_shop_group_list"
                      :items="data_shop_group_list"
                      item-key="shopName"
                      class="elevation-0 packhai-border-table"
                      :mobile-breakpoint="0"
                      :items-per-page="10000000"
                      hide-default-footer
                      >
                      <template v-slot:item.num="{ item }">
                        <font>{{ data_shop_group_list.indexOf(item) +1 }}</font>
                      </template>
                      <template v-slot:item.detail="{ item }">
                        <v-btn icon @click="get_uninvoice_group_list(item), data_item_uninvoice_group_list = item , selected_date_create_invoice=[]"><v-icon color="success">fa-file-alt</v-icon></v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="tab-2">
            <v-card flat>
              <v-card-text>
                <!-- {{ selected_date_create_invoice }} -->
                <v-row>
                  <v-col cols="12" align="left" class="pb-3 px-3">
                    <v-btn v-if="selected_date_create_invoice.length>0" class="ma-1" color="primary" @click="get_uninvoice_summary()">สรุปยอดและตัดเงินร้านค้า</v-btn>
                    <v-btn v-else class="ma-1" dark >สรุปยอดและตัดเงินร้านค้า</v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                      v-model="selected_date_create_invoice"
                      :headers="headers_uninvoice_group_list"
                      :items="data_uninvoice_group_list"
                      class="elevation-0 packhai-border-table"
                      :mobile-breakpoint="0"
                      :items-per-page="10000000"
                      hide-default-footer
                      item-key="date"
                      key="date"
                      show-select
                      >
                      <template v-slot:item.num="{ item }">
                        <font>{{ data_uninvoice_group_list.indexOf(item) +1 }}</font>
                      </template>
                      <template v-slot:item.detail="{ item }">
                        <v-btn icon @click="get_uninvoice_detail(item)"><v-icon color="success">fa-file-alt</v-icon></v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>


    <v-dialog v-model="dialog_show_detail_data_group" >
      <v-card elevation="1">
        <v-card-title>
          <font>รายละเอียดออเดอร์</font>
          <v-btn icon >
            <vue-excel-xlsx
                :data="data_shop_group_date_list"
                :columns="excel_group_date_detail_header"
                :file-name="'รายงานตารางสรุปยอดขาย'"
                :sheet-name="'รายงานตารางสรุปยอดขาย'"
                >
                <v-icon color="success">mdi-file-excel</v-icon>
            </vue-excel-xlsx>
          </v-btn>
          <v-spacer/>
          <v-btn icon @click="dialog_show_detail_data_group=false"><v-icon class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row class="mt-2 mx-0">
            <v-col cols="12" class=" px-0">

              <div style="height : 700px; overflow-y: auto">
                <!-- PC -->
                <v-data-table
                  :headers="headers_shop_group_date_list"
                  :items="data_shop_group_date_list"
                  item-key="shopName"
                  class="elevation-0 packhai-border-table"
                  :mobile-breakpoint="0"
                  :items-per-page="10000000"
                  hide-default-footer
                  >
                  <template v-slot:item.num="{ item }">
                    <font v-if="item.orderID != 'รวมยอด'">{{ data_shop_group_date_list.indexOf(item) +1 }}</font>
                  </template>
                  <template v-slot:item.expressCompanyLogo="{ item }">

                    <v-row>
                      <v-col cols="12" align="center" >
                        <v-img
                            v-if="item.expressCompanyLogo != null"
                            :src="item.expressCompanyLogo"
                            aspect-ratio="3"
                            class="grey lighten-2"
                            max-width="50"
                            max-height="50"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </template>

                </v-data-table>
              </div>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmCreateInvoiceDialog" max-width="1000" >
      <v-card elevation="1">
        <v-card-title class="pr-1">
          ยืนยันสรุปยอดและตัดเงิน
          <v-spacer/>
          <v-btn icon @click="confirmCreateInvoiceDialog=false"><v-icon class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text style="max-height: 700px" class="pl-7 pb-0 overflow-y-auto" v-if="data_uninvoice_summary_dict != null">
          <v-row class="py-2">
            <v-col cols="6" md="3">
              <strong>จำนวนออเดอร์</strong> :
            </v-col>
            <v-col cols="6" md="6" align="left">
              <span>{{ data_uninvoice_summary_dict.countOrder }}</span>
            </v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="6" md="3">
              <strong>วันที่</strong> :
            </v-col>
            <v-col cols="6" md="6" align="left">
              <span>{{ data_uninvoice_summary_dict.date }}</span>
            </v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="6" md="3">
              <strong>ชื่อร้าน</strong> :
            </v-col>
            <v-col cols="6" md="6" align="left">
              <span>{{ data_uninvoice_summary_dict.shopName }}</span>
            </v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="6" md="3">
              <strong>ค่าส่ง</strong> :
            </v-col>
            <v-col cols="6" md="6" align="left"><span>{{ data_uninvoice_summary_dict.deliveryPrice }}</span></v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="6" md="3">
              <strong>ค่าพื้นที่ห่างไกล</strong> :
            </v-col>
            <v-col cols="6" md="6" align="left"><span>{{ data_uninvoice_summary_dict.remotePrice }}</span></v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="6" md="3">
              <strong>ค่าCOD</strong> :
            </v-col>
            <v-col cols="6" md="6" align="left"><span>{{ data_uninvoice_summary_dict.codPrice }}</span></v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="6" md="3">
              <strong>ค่ากล่อง</strong> :
            </v-col>
            <v-col cols="6" md="6" align="left"><span>{{ data_uninvoice_summary_dict.boxPrice }}</span></v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="6" md="3">
              <strong>ค่าแพ็ค</strong> :
            </v-col>
            <v-col cols="6" md="6" align="left"><span>{{ data_uninvoice_summary_dict.packPrice }}</span></v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="6" md="3">
              <strong>ค่าบับเบิล</strong> :
            </v-col>
            <v-col cols="6" md="6" align="left"><span>{{ data_uninvoice_summary_dict.bubblePrice }}</span></v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="6" md="3">
              <strong>vrichFee</strong> :
            </v-col>
            <v-col cols="6" md="6" align="left"><span>{{ data_uninvoice_summary_dict.vrichFee }}</span></v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="6" md="3">
              <strong>ส่วนลด</strong> :
            </v-col>
            <v-col cols="6" md="6" align="left"><span>{{ data_uninvoice_summary_dict.discount }}</span></v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="6" md="3">
              <strong>ราคารวม</strong> :
            </v-col>
            <v-col cols="6" md="6" align="left" style="color: red;"><span>{{ data_uninvoice_summary_dict.totalPrice }}</span></v-col>
          </v-row>

          <v-divider class="my-3" />

          <div v-for="item in invoice_type_discount" :key="item.invoiceType">
            <v-row class="py-1" v-if="item.status == true">
              <v-col class="my-2" cols="12" md="2">
                <strong> {{ item.text }}</strong>
              </v-col>
              <v-col class="my-2" cols="12"  md="2" align="right">
                <v-text-field
                  v-model="item.sumData"
                  label="รวม"
                  dense
                  hide-details
                  disabled
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="my-2" cols="12"  md="2">
                <v-select
                 label="ประเภทส่วนลด"
                  v-model="item.isPercent"
                  :items="isPercentList"
                  dense
                  hide-details
                  @change="item.isPercent== false ? item.discountPercent = 0 : item.discountPercent , item.discount = 0, item.total = item.sumData"
                  outlined
                ></v-select>
              </v-col>
              <v-col class="my-2" cols="12"  md="2" align="right">
                <v-text-field
                  label="ส่วนลด %"
                  v-model="item.discountPercent"
                  @keypress="isNumberWithDot()"
                  dense
                  hide-details
                  outlined
                  @change="calculate_discount('percent', item)"
                  :disabled="!item.isPercent"
                ></v-text-field>
              </v-col>
              <v-col class="my-2" cols="12"  md="2" align="right">
                <v-text-field
                  v-model="item.discount"
                  label="ส่วนลดบาท"
                  @keypress="isNumberWithDot()"
                  @change="calculate_discount('bath', item)"
                  dense
                  hide-details
                  outlined
                  :disabled="item.isPercent"
                ></v-text-field>
              </v-col>
              <v-col class="my-2" cols="12"  md="2" align="right">
                <v-text-field
                  v-model="item.total"
                  label="คงเหลือ"
                  dense
                  hide-details
                  disabled
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider v-if="item.status == true" class="my-1"/>
          </div>

          <!-- <v-divider class="my-3" /> -->


        </v-card-text>
        <v-card-actions class="mx-2">
     
          <v-row>
            <v-col cols="6" align="left">
              <v-radio-group
              v-model="createdInvoiceType"
             row
            >
              <v-radio
                label="ตัดเงินแต่ยังไม่สร้างใบแจ้งหนี้"
                value="2"
              ></v-radio>
              <v-radio
                label="ตัดเงินและสร้างใบแจ้งหนี้ทันที"
                value="3"
              ></v-radio>
            </v-radio-group>
            </v-col>
            <v-col cols="6" align="right">
              <v-btn dark color="primary" class="my-1"  @click="createInvoice()"><v-icon class="ma-1">mdi-content-save</v-icon>ดำเนินการ</v-btn>
            </v-col>
          </v-row>
         
         
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import axios from 'axios' 
  import Swal from 'sweetalert2'
  import Loading from '@/website/components/Loading'
  import { financialService, manageShopService, reportService_dotnet, shopService_dotnet } from '@/website/global'
  import { AlertSuccess } from '@/website/global_alert'
  import { FormatInvoice, FormatOrder, formatMoney, isNumberWNoDot, isNumberWithDot } from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data: () => ({
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      page_loading: true,
      loading: false,
      createdInvoiceType:'3',
      navigete: [],
      dialog_show_detail_data_group: false,
      list_group_date_for_create_onvoice: [],
      tab: 'tab-1',

      shop_date_type_select : 1,
      shop_date_type_list : [{'id': 1, 'name': 'วันที่แพ็คสินค้าสำเร็จ'},{'id': 2, 'name': 'วันที่ส่งให้บริษัทขนส่ง'}],

      data_item_uninvoice_group_list : null,
      data_shop_group_list: [],
      headers_shop_group_list:[
        { text: 'ลำดับ', value: 'num', sortable: false, align: 'center'},
        { text: 'ร้านค้า', value: 'shopName', sortable: false, align: 'left'},
        { text: 'จำนวนวันที่', value: 'countDate', sortable: false, align: 'right'},
        { text: 'จำนวนออเดอร์', value: 'countOrder', sortable: false , align: 'right'},
        { text: 'รายละเอีด', value: 'detail', sortable: false , align: 'center'},
      ],

      data_uninvoice_group_list: [],
      headers_uninvoice_group_list:[
        { text: 'ลำดับ', value: 'num', sortable: false, align: 'center'},
        { text: 'ร้านค้า', value: 'shopName', sortable: false, align: 'left'},
        { text: 'วันที่', value: 'date', sortable: false, align: 'center'},
        { text: 'จำนวนออเดอร์', value: 'countOrder', sortable: false , align: 'right'},
        { text: 'รายละเอีด', value: 'detail', sortable: false , align: 'center'},
      ],


      data_uninvoice_summary_dict: null,
      headers_uninvoice_summary_dict: [
        { text: 'ลำดับ', value: 'num', sortable: false, align: 'center'},
        { text: 'ออเดอร์', value: 'orderID', sortable: false, align: 'center'},
        { text: 'วันที่', value: 'date', sortable: false, align: 'center'},
        { text: 'จำนวนออเดอร์', value: 'countOrder', sortable: false, align: 'center'},
        { text: 'ค่าส่ง', value: 'deliveryPrice', sortable: false , align: 'right'},
        { text: 'ค่าพื้นที่ห่างไกล', value: 'remotePrice', sortable: false , align: 'right'},
        { text: 'ค่า COD', value: 'codPrice', sortable: false , align: 'right'},
        { text: 'ค่ากล่อง', value: 'boxPrice', sortable: false , align: 'right'},
        { text: 'ค่าแพ็ค', value: 'packPrice', sortable: false , align: 'right'},
        { text: 'ค่าบับเบิล', value: 'bubblePrice', sortable: false , align: 'right'},
        { text: 'ส่วนลด', value: 'discount', sortable: false , align: 'right'},
        { text: 'vrichFee', value: 'vrichFee', sortable: false , align: 'right'},
        { text: 'ราคารวม', value: 'totalPrice', sortable: false , align: 'right'},
      ],

      data_shop_group_date_list: [],
      headers_shop_group_date_list: [
        { text: 'ลำดับ', value: 'num', sortable: false, align: 'center'},
        { text: 'ออเดอร์', value: 'orderID', sortable: false, align: 'center'},
        { text: 'โลโก้ขนส่ง', value: 'expressCompanyLogo', sortable: false , align: 'center'},
        { text: 'ชื่อขนส่ง', value: 'expressCompanyName', sortable: false , align: 'left'},
        { text: 'เลขแทรค', value: 'trackingNo', sortable: false , align: 'center'},
        { text: 'แพควันที่', value: 'packedDate', sortable: false , align: 'center'},
        { text: 'ส่งให้ขนส่ง', value: 'sendToExpressDateTime', sortable: false , align: 'center'},
        { text: 'ค่าส่ง', value: 'deliveryPrice', sortable: false , align: 'right'},
        { text: 'ค่าพื้นที่ห่างไกล', value: 'remotePrice', sortable: false , align: 'right'},
        { text: 'ค่า COD', value: 'codPrice', sortable: false , align: 'right'},
        { text: 'ค่ากล่อง', value: 'boxPrice', sortable: false , align: 'right'},
        { text: 'ค่าแพ็ค', value: 'packPrice', sortable: false , align: 'right'},
        { text: 'ค่าบับเบิล', value: 'bubblePrice', sortable: false , align: 'right'},
        { text: 'ส่วนลด', value: 'discount', sortable: false , align: 'right'},
        { text: 'vrichFee', value: 'vrichFee', sortable: false , align: 'right'},
        { text: 'ค่าบริการอื่นๆ', value: 'otherServicePrice', sortable: false , align: 'right'},
        { text: 'ราคารวม', value: 'totalPrice', sortable: false , align: 'right'},
      ],

      // invoice
      confirmCreateInvoiceDialog: false,
      selected_date_create_invoice: [],
      createInvoice_date_list: [],
      createInvoice_shopID: null,

      // get all shop
      sh_shop_select: null,
      sh_shop_list: [],

      //discount invoice
      invoice_type_discount: [],
      isPercentList: [
        { value: true, text: "ตาม%"},
        { value: false, text: "ตามยอดเงิน"}
      ],


      // excel
      excel_group_date_detail_header : [

        { label: "ออเดอร์", field: "orderID", width: 15},
        { label: "ชื่อขนส่ง", field: "expressCompanyName", width: 20},
        { label: "เลขแทรค", field: "trackingNo", width: 20},
        { label: "แพควันที่", field: "packedDate", width: 20},
        { label: "ส่งให้ขนส่ง", field: "sendToExpressDateTime", width: 20},
        { label: "ค่าส่ง", field: "deliveryPrice", width: 15},
        { label: "ค่าพื้นที่ห่างไกล", field: "remotePrice", width: 15},
        { label: "ค่า COD", field: "codPrice", width: 15},
        { label: "ค่ากล่อง", field: "boxPrice", width: 15},
        { label: "ค่าแพ็ค", field: "packPrice", width: 15},
        { label: "ค่าบับเบิล", field: "bubblePrice", width: 15},
        { label: "ส่วนลด", field: "discount", width: 15},
        { label: "vrichFee", field: "vrichFee", width: 15},
        { label: "ค่าบริการอื่นๆ", field: "otherServicePrice", width: 15},
        { label: "ราคารวม", field: "totalPrice", width: 15},
      ],





    }),
    async created() {
      this.navigete = JSON.parse(localStorage.getItem('navigete'))
      this.get_all_shop_in_branch()
      this.get_uninvoice_group(null)
 
      this.page_loading = false
    },
    computed: {
    },
    watch: {
    },
    methods: {
      async get_all_shop_in_branch (shopID) {
        this.loading = true
        let res1 = await axios.post(shopService_dotnet+'Shop/get-shop-list', {
          "branchID": localStorage.getItem('Branch_BranchID')
        },
        { headers: this.header_token})

        var new_shop_list = [{"id": null, "name": '-- ร้านค้าทั้งหมด --' }]
        var shop_format = null
        if(res1.data.result != null){
          for (var i = 0; i < res1.data.result.length; i++) {
            shop_format = {"id": res1.data.result[i].shop.id, "name": res1.data.result[i].shop.name }
            new_shop_list.push(shop_format)
          }
        }

        this.sh_shop_list = new_shop_list
        this.loading = false
      },

      async get_uninvoice_group (shopID) {
        this.loading = true
        let res1 = await axios.post(reportService_dotnet+'Invoice/uninvoice-group', {
          "branchID": localStorage.getItem('Branch_BranchID'),
          "shopID": shopID,
          "dateType": this.shop_date_type_select
        },
        { headers: this.header_token})
        // console.log(res1);
        this.data_shop_group_list = res1.data
        this.loading = false
      },

      async get_uninvoice_detail (item) {
        // console.log(item);
        this.loading = true
       
        const myArr = item.date.split("-")
        var new_date = myArr[2]+'-'+myArr[1]+'-'+myArr[0]
        let res1 = await axios.post(reportService_dotnet+'Invoice/uninvoice-detail', {
          "shopID": item.shopID,
          "dateType": item.dateType,
          "date": new_date
        },
        { headers: this.header_token})
        this.data_shop_group_date_list = []
        this.data_shop_group_date_list = res1.data
        this.dialog_show_detail_data_group = true
        this.loading = false

      },

      async get_uninvoice_group_list (item) {
        this.loading = true
        let res1 = await axios.post(reportService_dotnet+'Invoice/uninvoice-group-list', {
          "branchID": localStorage.getItem('Branch_BranchID')  ,
          "shopID": item.shopID,
          "dateType": item.dateType,
          "skip": 0,
          "take": 1000000
        },
        { headers: this.header_token})
        // console.log(res1);

        if(item.dateType == 1){
          this.headers_uninvoice_group_list[2].text = 'วันที่แพ็คสินค้า'
        } else {
          this.headers_uninvoice_group_list[2].text = 'วันที่ส่งให้บริษัทขนส่ง'
        }
        this.data_uninvoice_group_list = []
        this.data_uninvoice_group_list = res1.data
        this.tab = 'tab-2'
        this.loading = false
      },

      async get_uninvoice_summary () {
        this.createInvoice_date_list = []
        this.createInvoice_shopID = null
        this.createInvoice_shopID = this.selected_date_create_invoice[0].shopID
        var new_date_list = []
        for (var i = 0; i < this.selected_date_create_invoice.length; i++) {
          const myArr = this.selected_date_create_invoice[i].date.split("-")
          var new_date = myArr[2]+'-'+myArr[1]+'-'+myArr[0]
          new_date_list.push(new_date)
        }
        this.createInvoice_date_list = new_date_list

        this.loading = true
        let res1 = await axios.post(reportService_dotnet+'Invoice/uninvoice-summary', {
          "shopID": this.selected_date_create_invoice[0].shopID,
          "dateType": this.selected_date_create_invoice[0].dateType,
          "dateList": new_date_list
        },
        { headers: this.header_token})
        this.data_uninvoice_summary_dict = null
        this.data_uninvoice_summary_dict = res1.data


        var InvoiceGroup_tmp = {} ;
        var invoice_type_discount = [
          { invoiceType : 1 ,text: "ใบแจ้งหนี้ค่าขนส่ง" ,isPercent: false, discountPercent: 0 ,discount : 0 , sumData: 0, total: 0, status: false},
          { invoiceType : 2 ,text: "ใบแจ้งหนี้ค่าบริการ" ,isPercent: false, discountPercent: 0 ,discount : 0 , sumData: 0, total: 0, status: false },
          { invoiceType : 3 ,text: "ใบแจ้งหนี้ค่า COD" ,isPercent: false, discountPercent: 0 ,discount : 0 , sumData: 0, total: 0, status: false }
        ] ;

        InvoiceGroup_tmp.DeliveryPrice = this.data_uninvoice_summary_dict.deliveryPrice
        InvoiceGroup_tmp.RemotePrice = this.data_uninvoice_summary_dict.remotePrice
        InvoiceGroup_tmp.CODPrice = this.data_uninvoice_summary_dict.codPrice
        InvoiceGroup_tmp.Discount = this.data_uninvoice_summary_dict.discount
        InvoiceGroup_tmp.PackPrice = this.data_uninvoice_summary_dict.packPrice
        InvoiceGroup_tmp.BoxPrice = this.data_uninvoice_summary_dict.boxPrice
        InvoiceGroup_tmp.BubblePrice = this.data_uninvoice_summary_dict.bubblePrice
        InvoiceGroup_tmp.VrichFee = this.data_uninvoice_summary_dict.vrichFee

        if( InvoiceGroup_tmp.DeliveryPrice != 0 ){
          invoice_type_discount[0].sumData = parseFloat(InvoiceGroup_tmp.DeliveryPrice.replace(/,/g, '')) + parseFloat(InvoiceGroup_tmp.RemotePrice.replace(/,/g, ''))
          invoice_type_discount[0].total = parseFloat(InvoiceGroup_tmp.DeliveryPrice.replace(/,/g, '')) + parseFloat(InvoiceGroup_tmp.RemotePrice.replace(/,/g, ''))
          invoice_type_discount[0].status = true
        }



        if( InvoiceGroup_tmp.BoxPrice != 0 || InvoiceGroup_tmp.PackPrice != 0 || InvoiceGroup_tmp.BubblePrice != 0 || InvoiceGroup_tmp.VrichFee != 0 ){
          invoice_type_discount[1].sumData = parseFloat(InvoiceGroup_tmp.BoxPrice.replace(/,/g, '')) + parseFloat(InvoiceGroup_tmp.PackPrice.replace(/,/g, '')) + parseFloat(InvoiceGroup_tmp.BubblePrice.replace(/,/g, '')) + parseFloat(InvoiceGroup_tmp.VrichFee.replace(/,/g, ''))
          invoice_type_discount[1].total = parseFloat(InvoiceGroup_tmp.BoxPrice.replace(/,/g, '')) + parseFloat(InvoiceGroup_tmp.PackPrice.replace(/,/g, '')) + parseFloat(InvoiceGroup_tmp.BubblePrice.replace(/,/g, '')) + parseFloat(InvoiceGroup_tmp.VrichFee.replace(/,/g, ''))
          invoice_type_discount[1].status = true
        }

        if( InvoiceGroup_tmp.CODPrice != 0 ){
          invoice_type_discount[2].sumData = parseFloat(InvoiceGroup_tmp.CODPrice.replace(/,/g, ''))
          invoice_type_discount[2].total = parseFloat(InvoiceGroup_tmp.CODPrice.replace(/,/g, ''))
          invoice_type_discount[2].status = true
        }

        this.invoice_type_discount = invoice_type_discount

        this.createdInvoiceType='3'
        this.confirmCreateInvoiceDialog = true
        this.loading = false
      },

      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDate (date) {
        if (date.length == 0) return null

        if (date.length == 1) {
          const [year, month, day] = date[0].split('-')
          return `${day}-${month}-${year}`
        } else {
          const [year_from, month_from, day_from] = date[0].split('-')
          const [year_to, month_to, day_to] = date[1].split('-')
          if (date[0] < date[1]) {
            return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
          } else {
            return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
          }
        }
      },

      async createInvoice() {

        Swal.fire({
          title: 'ต้องการสร้างบิล',
          text: "ต้องการตัดเงิน "+(this.createdInvoiceType=='3'? "+ สร้างใบแจ้งหนี้":"แต่ยังไม่สร้างใบแจ้งหนี้"),
          icon: 'question',
          showCancelButton: true,
          cancelButtonText:'ยกเลิก',
          confirmButtonText: 'ดำเนินการ'
        }).then(async (result) =>  {
          if (result.isConfirmed) {

            var dateType = null
            if(this.data_uninvoice_summary_dict != null){
              dateType = this.data_uninvoice_summary_dict.dateType
            }

            var discountRequest = []
            for (var i = 0; i < this.invoice_type_discount.length; i++) {
              var param_tmp = {id: this.invoice_type_discount[i].invoiceType, text: this.invoice_type_discount[i].text, discountPercent: this.invoice_type_discount[i].discountPercent , discount: this.invoice_type_discount[i].discount}
              discountRequest.push(param_tmp)
            }

            // console.log(discountRequest);
            // var id = 1
            // if(id == 1){
            //   return
            // }
            this.loading = true
            // dotnet
            let response = await axios.post(reportService_dotnet+'Invoice/gen-invoice-branch-shop', {
              'branchID': localStorage.getItem('Branch_BranchID'),
              'staffBranchID': localStorage.getItem('Branch_StaffID'),
              'shopID': this.createInvoice_shopID,
              'dateType':  dateType,
              'dateList': this.createInvoice_date_list,
              'discountRequest': discountRequest,
              'autoInvoiceType':this.createdInvoiceType
            },
            { headers: this.header_token})
            this.loading = false
            if(response.data == "สร้างบิลเรียบร้อย") {
                this.AlertSuccess()
                this.confirmCreateInvoiceDialog = false
                // this.selectedShop()
            }

            this.get_uninvoice_group_list(this.data_item_uninvoice_group_list)
          }
        })


       

      },

      goto_create_bill () {
        window.open('/create-bill', '_blank');
      },

      calculate_discount (type , item) {
        if(type == 'percent'){
          if(item.discountPercent > 0) {
            var percent = 100
            var dis_percent = 0
            dis_percent = (item.sumData * item.discountPercent )/ percent
            item.discount = dis_percent
            item.total = item.sumData - dis_percent
          } else {
            item.discountPercent = 0
            item.discount = 0
          }
        } else {
          if(item.discount > 0) {
            item.total = item.sumData - item.discount
          } else {
            item.discount = 0
          }

        }
      },
      FormatInvoice,
      FormatOrder,
      formatMoney,
      isNumberWNoDot,
      isNumberWithDot,
      AlertSuccess,
    }
  }
</script>

<style scoped>
  ::v-deep .v-data-table__empty-wrapper {
    display: none;
  }
  .mobile-none {
    display: ;
  }
  .pc-none {
    display: none;
  }
  @media (max-width: 599px) {
    .mobile-none {
      display: none;
    }
    .pc-none {
      display: ;
    }
  }
</style>
